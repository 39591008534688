// Fonts
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

// Variables
@import 'variables';

// Bootstrap
@import '~bootstrap/scss/bootstrap';

// AdminLTE
@import "~admin-lte/build/scss/AdminLTE-raw";

// Material Design Icons
@import '~@mdi/font/scss/materialdesignicons';

// Select2
@import "~select2/dist/css/select2.css";
@import "~@ttskch/select2-bootstrap4-theme/dist/select2-bootstrap4.css";

// Croppie
@import "~croppie/croppie.css";

// PhotoSwipe Gallery
@import "~photoswipe/dist/photoswipe.css";
@import "~photoswipe/dist/default-skin/default-skin.css";